import { API } from 'utils/const';
import apiService from './apiService';

const getAllInstructors = (user) => apiService.get(`${API}/instructors`, user);

const getInstructor = (id, user) => apiService.get(`${API}/instructors/${id}`, user);

const createInstructor = (data, user) => apiService.post(`${API}/instructors`, data, user);

const updateInstructor = (id, data, user) => apiService.put(`${API}/instructors/${id}`, data, user);

const deleteInstructor = (id, user) => apiService.delete(`${API}/instructors/${id}`, user);

export {
    getAllInstructors,
    getInstructor,
    createInstructor,
    updateInstructor,
    deleteInstructor
};
