import React from 'react';
import moment from 'moment';
import background from 'assets/course-background.jpg';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FACULTY_MAP } from 'utils/text';
import './courses.css';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const Courses = ({ originalCourses, courses, openForm, filterCourses, showDescription, instructors }) => {
    const getTooltip = (name) => {
        const instructor = instructors.find(i => `${i.firstName} ${i.lastName}` === name);
        if (!instructor) {
            return null;
        }
        const title = `${instructor.firstName} ${instructor.lastName}${instructor.education ? ', ' + instructor.education : ''}`;
        return (
            <div key={title} className="tutor-container">
                <img src={instructor.imageUrl} alt="Person face" />
                <h5>{title}</h5>
                <span style={{ whiteSpace: 'pre-wrap' }}>{instructor.description}</span>
            </div>
        );
    }

    return (
        <div className="courses">
            <h1>Kursevi</h1>
            <div className="filter-container">
                {
                    originalCourses && Array.from(new Set(originalCourses.map(c => c.faculty))).map((faculty) => (
                        <Button variant="contained" color="primary" onClick={() => filterCourses(faculty)} key={faculty}>
                            {FACULTY_MAP[faculty]}
                        </Button>
                    ))
                }
            </div>
            <div className="courses-container">
                {courses && instructors && courses.map(course => (
                    <div className="course-outter-container" id={course.name} key={course.id}>
                        <div className="course-container">
                            <div className="course-image-container">
                                <img src={background} alt="Pencils" />
                                <h4>{course.name}</h4>
                            </div>
                            <div className="course-details-container">
                                {<>Fakultet: {FACULTY_MAP[course.faculty]}<br /></>}
                                Broj časova: {course.numberOfLessons}<br />
                                Lokacija: {course.location[0] + course.location.substr(1).toLowerCase().replace('_', ' ')}<br />
                                Pocetak: {moment(course.startDate).format('DD.MM.YYYY.')}<br />
                                Cijena: {course.price}KM<br />
                                <span className="professors-span">Predavači: 
                                    {course.professors.split(/, |,| i /).map((name, index) =>
                                        <HtmlTooltip enterTouchDelay={50} placement="right" key={name} title={getTooltip(name) || ''}>
                                            <a href="/" onClick={(e) => { e.preventDefault(); return false; }} style={{ textDecoration: 'none' }}>
                                                {index === 0 ? ' ' : (index < course.professors.split(/, |,| i /).length - 1 ? ', ' : ' i ')}
                                                <span className="professor-name">{name}</span>
                                            </a>
                                        </HtmlTooltip>
                                    )}
                                </span><br />
                                <br />
                                Opis:<br />
                                <span className={course.showDescription ? '' : 'description-limiter'}>
                                    {course.description.split('\n').map((row, i) => (<span key={i}>{row}<br /></span>))}
                                </span><br />
                                {!course.showDescription &&
                                    <Button color="primary" onClick={() => showDescription(course.id)}><KeyboardArrowDownIcon />Pogledaj cijeli opis</Button>}
                                <br />
                                <Button variant="contained" color="primary" onClick={() => openForm(course.link)}>Prijavi se</Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Courses;
