import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './register.css';

const Register = ({ data, onDataChange, onSubmit, error, saving }) => {
    return (
        <div className="register-container">
            <div className="register-box">
                <form onSubmit={onSubmit}>
                    <h3>Registracija</h3>

                    <TextField
                        label="Ime"
                        name="firstName"
                        value={data.firstName}
                        onChange={onDataChange}
                        error={error.firstName}
                        helperText={error.firstName}
                    />

                    <TextField
                        label="Prezime"
                        name="lastName"
                        value={data.lastName}
                        onChange={onDataChange}
                        error={error.lastName}
                        helperText={error.lastName}
                    />

                    <TextField
                        label="Email"
                        name="email"
                        value={data.email}
                        onChange={onDataChange}
                        error={error.email}
                        helperText={error.email}
                    />

                    <TextField
                        label="Lozinka"
                        name="password"
                        type="password"
                        value={data.password}
                        onChange={onDataChange}
                        error={error.password}
                        helperText={error.password}
                    />

                    <Button type="submit" variant="contained" color="primary">
                        { saving ? <CircularProgress className="button-progress" color="secondary" /> : 'Registruj se'}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default Register;
