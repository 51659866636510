import React from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

const InstructorsTable = ({ instructors, addInstructor, editInstructor, onDeleteInstructor  }) => {
    return (
        <>
            <div className="admin-dashboard-add-container">
                <h1>Predavač</h1>
                <Button variant="contained" color="primary" onClick={addInstructor}>Dodaj novog predavača</Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Ime</TableCell>
                            <TableCell align="center">Prezime</TableCell>
                            <TableCell align="center">Zvanje</TableCell>
                            <TableCell align="center">Akcije</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {instructors && instructors.map((instructor) => (
                            <TableRow key={instructor.id}>
                                <TableCell component="th" scope="row" align="left">{instructor.firstName}</TableCell>
                                <TableCell align="center">{instructor.lastName}</TableCell>
                                <TableCell align="center">{instructor.education}</TableCell>
                                <TableCell align="center">
                                    <EditIcon className="button-icon" onClick={() => editInstructor(instructor)} />
                                    {instructor.deleting ?
                                        <CircularProgress className="action-progress" color="black" /> :
                                        <DeleteIcon className="button-icon" onClick={() => onDeleteInstructor(instructor.id)} />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default InstructorsTable;
