import React, { useState, useContext, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import cookie from 'js-cookie';

import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import { API } from 'utils/const';

import './menu.css';

const Menu = ({ toast, history }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [user, setUserState] = useState();
    const [courses, setCourses] = useState();
    const { getUser, setUser } = useContext(UserContext);
    const { setToast } = useContext(ToastContext);

    useEffect(() => {
        getUser().then(usr => {
            setUserState(usr);
        })
    }, [getUser]);

    useEffect(() => {
        fetch(`${API}/courses`).then(res => {
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                return;
            }

            res.json().then(json => {
                setCourses(json);
            });
        }).catch(err => console.error(err));
    }, []);

    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    const navigate = route => {
        history.push(route);
        closeDrawer();
    };

    const logout = () => {
        cookie.remove('token')
        setUser(null);
        setToast({ type: 'success', message: 'Uspješno ste se odjavili' });
        history.push('/');
        closeDrawer();
    };

    return (
        <nav className="navbar">
            <div className="navbar-left-aligned">
                <IconButton onClick={() => setDrawerOpen(true)}>
                    <MenuIcon />
                </IconButton>
            </div>
            <div className="navbar-name">
                <Link to="/">Math Solutions</Link>
            </div>
            <div className="navbar-right-aligned">
                <Link to="/kursevi">Kursevi</Link>
                <Link to="/kontakt">Kontakt</Link>
                { !user && <>
                    <Link to="/prijava">Prijavi se</Link>
                    <Link to="/registracija">Registracija</Link>
                </>}
                { user && <>
                    { user.admin && <Link to="/admin/dashboard">Admin</Link> }
                    <Link to="/profil">Profil</Link>
                    <span onClick={logout}>Odjavi se</span>
                </>}
            </div>

            <Drawer className="drawer" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                <h4 className="uppercase bold">Menu</h4>
                <h6 className="uppercase" onClick={() => navigate('/')}>Naslovna</h6>
                <h6 className="uppercase" onClick={() => navigate('/kursevi')}>Kursevi</h6>
                {courses && courses.filter(c => c.status === 'OBJAVLJEN').map(course => (
                    <HashLink key={course.name} className="uppercase submenu" to={`/kursevi#${course.name}`} onClick={closeDrawer}>{course.name}</HashLink>
                ))}
                <h6 className="uppercase" onClick={() => navigate('/kontakt')}>Kontakt</h6>
                { !user && <>
                    <h6 className="uppercase" onClick={() => navigate('/prijava')}>Prijavi se</h6>
                    <h6 className="uppercase" onClick={() => navigate('/registracija')}>Registracija</h6>
                </>}
                { user && <>
                    { user.admin && <h6 className="uppercase" onClick={() => navigate('/admin/dashboard')}>Admin</h6> }
                    <h6 className="uppercase" onClick={() => navigate('/profil')}>Profil</h6>
                    <h6 className="uppercase" onClick={logout}>Odjavi se</h6>
                </>}
                <IconButton className="close-drawer" onClick={() => setDrawerOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Drawer>

            {
                toast &&
                <Snackbar open={!!toast}>
                    <Alert variant="filled" severity={toast.type}>
                        {toast.message}
                    </Alert>
                </Snackbar>
            }
        </nav>
    );
};

export default withRouter(Menu);
