import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import cookie from 'js-cookie';
import Register from './Register';
import ToastContext from 'contexts/ToastContext';
import UserContext from 'contexts/UserContext';
import { EMAIL_REGEX, API_V2 } from 'utils/const';
import { getRequiredError } from 'utils/text';

const RegisterContainer = ({ history }) => {
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [error, setError] = useState({});
    const [saving, setSaving] = useState(false);
    const { setToast } = useContext(ToastContext);
    const { getUser, setUser } = useContext(UserContext);

    useEffect(() => {
        getUser().then(user => {
            if (user) {
                navigate(user);
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = (user) => {
        if (user.admin) {
            history.push('/admin/dashboard');
        } else {
            history.push('/profil');
        }
    }

    const onDataChange = e => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setError({ ...error, [name]: null });
    };

    const validate = () => {
        const err = {};

        if (!data.firstName) {
            err.firstName = getRequiredError('ime');
        }

        if (!data.lastName) {
            err.lastName = getRequiredError('prezime');
        }

        if (!data.email) {
            err.email = getRequiredError('email');
        } else if (!EMAIL_REGEX.test(data.email)) {
            err.email = 'Molimo provjerite tačnost emaila.'
        }

        if (!data.password) {
            err.password = getRequiredError('lozinku');
        }

        setError(err);
        return Object.keys(err).length === 0;
    }

    const onSubmit = e => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setSaving(true);
        fetch(API_V2 + '/users/register', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setSaving(false);
            if (res.status !== 200) {
                res.json().then(err => {
                    setToast({ type: 'error', message: err.message });
                });
                return;
            }

            res.json().then(user => {
                cookie.set('token', user.token, { expires: 10 * 365 });
                setUser(user);
                setToast({ type: 'success', message: 'Dobrodošli, ' + user.firstName });
                navigate(user);
            });
        }).catch(err => {
            console.error(err);
            setSaving(false);
            setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
        });
    };

    return (
        <Register
            data={data}
            onDataChange={onDataChange}
            error={error}
            saving={saving}
            onSubmit={onSubmit}
        />
    );
};

export default withRouter(RegisterContainer);
