import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import homepage from '../../assets/homepage.jpg';
import Animation from './Animation.jsx';
import { FACULTY_MAP } from 'utils/text';
import './home.css';

const Home = ({ openCourses, texts, instructors, courses }) => {
    const [showSocial, setShowSocial] = useState(false);

    return (
        <div className="homepage-container">
            <div className="homepage-image-container">
                <img className="homepage-image" src={homepage} alt="Femal car  rying books" />
                <div className="homepage-image-shaddow">
                    <h1>Math Solutions</h1>
                    <h3>{texts.HEADER}</h3>
                    <h3>Vaš uspjeh zagarantovan.</h3>
                    <p className="header-notifcation">{texts.HEADER_STATUS}</p>
                    <div className="header-social">
                        <div className="header-social-inner" style={{ width: showSocial ? '96px' : 0, opacity: showSocial ? 1 : 0 }}>
                            <InstagramIcon onClick={() => { window.open('https://www.instagram.com/mathsolutions.ba/', '_blank'); }} />
                            <FacebookIcon onClick={() => { window.open('https://www.facebook.com/mathsolutions.ba', '_blank'); }} />
                        </div>
                        <ShareIcon onClick={() => setShowSocial(show => !show)} />
                    </div>
                    <Button variant="contained" color="primary" onClick={openCourses}>Pregledaj kurseve</Button>
                </div>
            </div>

            <div
                className="centered-text"
                dangerouslySetInnerHTML={{ __html: texts.MAIN }}
            />

            <div className="animation-section-container">
                <Animation />
                <div className="animation-section-text">
                    <h4 className="bold uppercase">Kursevi</h4>

                    <p>
                        {courses && Array.from(new Set(courses.map(c => c.faculty))).map(faculty => (
                            <Fragment key={faculty}>
                                {FACULTY_MAP[faculty]}:<br />
                                {courses.filter(c => c.faculty === faculty).map(course => (
                                    <Fragment key={course.name}>
                                        {course.name}<br />
                                    </Fragment>
                                ))}
                                <br />
                            </Fragment>
                        ))}
                    </p>

                    <Button variant="contained" color="primary" onClick={openCourses}>Pregledaj kurseve</Button>
                </div>
            </div>

            <div className="centered-text">
                <h4 className="bold uppercase">Predavači</h4>

                <div className="tutors-container">
                    {
                        instructors && instructors.map(instructor => (
                            <div key={instructor.id} className="tutor-container">
                                <img src={instructor.imageUrl} alt="Person face" />
                                <h5>{`${instructor.firstName} ${instructor.lastName}${instructor.education ? ', ' + instructor.education : ''}`}</h5>
                                <span style={{ whiteSpace: 'pre-wrap' }}>{instructor.description}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Home;
