import { useState } from 'react';
import { API } from '../utils/const';

export const useTexts = () => {
    const [texts, setTexts] = useState();

    const getTexts = () => {
        return new Promise((resolve) => {
            if (texts) {
                resolve(texts);
                return;
            }

            fetch(API + '/misc/texts').then(res => {
                if (res.status !== 200) {
                    resolve(null);
                    return;
                }

                res.json().then(txt => {
                    setTexts(txt);
                    resolve(txt);
                });
            }).catch(err => {
                console.error(err);
                resolve(null);
            });
        });
    };

    return {
        getTexts,
        setTexts
    };
};

export default useTexts;
