import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { ANALYTICS_ID } from 'utils/const';

import Menu from 'components/shared/Menu';
import OnRouteChange from 'components/shared/OnRouteChange';
import Home from 'components/Home';
import ContactUs from 'components/ContactUs';
import Courses from 'components/Courses';
import Register from 'components/Register';
import Profile from 'components/Profile';
import Login from 'components/Login';
import AdminDashbaord from 'components/AdminDashboard';
//import Instructors from 'components/Instructors';

import ToastContext from 'contexts/ToastContext';
import UserContext from 'contexts/UserContext';
import TextsContext from 'contexts/TextsContext';
import useToast from 'hooks/useToast';
import useUser from 'hooks/useUser';
import useTexts from 'hooks/useTexts';

ReactGA.initialize(ANALYTICS_ID);

const App = () => {
    const { toast, setToast } = useToast();
    const user = useUser();
    const texts = useTexts();
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#00C6FF'
            }
        }
    });

    return (
        <UserContext.Provider value={user}>
            <TextsContext.Provider value={texts}>
                <ToastContext.Provider value={{ toast, setToast }}>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <Menu toast={toast} />
                            <OnRouteChange />
                            <div className="content">
                                <Switch>
                                    <Route exact path='/' component={Home} />
                                    <Route path='/kontakt' component={ContactUs} />
                                    <Route path='/kursevi' exact component={Courses} />

                                    <Route path='/prijava' component={Login} />
                                    <Route path='/registracija' component={Register} />
                                    <Route path='/profil' component={Profile} />

                                    <Route path='/admin/dashboard' component={AdminDashbaord} />
                                </Switch>
                            </div>
                        </Router>
                    </ThemeProvider>
                </ToastContext.Provider>
            </TextsContext.Provider>
        </UserContext.Provider>
    );
}


export default App;
