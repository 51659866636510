import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Field from 'components/shared/Field';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import CourseForm from 'components/CourseForm';
import { FACULTY_MAP } from 'utils/text';
import { ANALYTICS_VIEW_ID } from 'utils/const';
import {
    AnalyticsDashboard,
    SessionsByDateChart,
    PageViewsPerPathChart,
} from 'react-analytics-charts';
import './admin-dashboard.css'
import InstructorsTable from './InstructorsTable';
import InstructorForm from 'components/InstructorForm';

const AdminDashboard = ({
    activeTab, setActiveTab, analyticsToken, courses, showCourseForm, setShowCourseForm, deleteCourse, editCourse,
    courseToEdit, onCancelFormCourse, onDragEnd, onSaveTexts, savingTexts, headerText, onHeaderTextChange,
    headerStatusText, onHeaderStatusTextChange, mainText, onMainTextChange,
    instructors, addInstructor, editInstructor, instructorToEdit, onDeleteInstructor, showInstructorForm, onCancelFormInstructor
}) => {
    return (
        <>
            <div className="admin-dashboard-container-wrapper">
                <ul className="admin-dashboard-navigation">
                    <img src={window.location.origin + '/img/logo3.png'} alt="Math Solutions logo" />
                    <li className={activeTab === 'courses' ? 'active' : ''} onClick={() => setActiveTab('courses')}>Kursevi</li>
                    <li className={activeTab === 'instructors' ? 'active' : ''} onClick={() => setActiveTab('instructors')}>Predavači</li>
                    <li className={activeTab === 'texts' ? 'active' : ''} onClick={() => setActiveTab('texts')}>Tekstovi</li>
                    <li className={activeTab === 'analytics' ? 'active' : ''} onClick={() => setActiveTab('analytics')}>Analitika</li>
                </ul>
                <div className="admin-dashboard-container">
                    {
                        activeTab === 'courses' &&
                        <>
                            <div className="admin-dashboard-add-container">
                                <h1>Kursevi</h1>
                                <Button variant="contained" color="primary" onClick={() => setShowCourseForm(true)}>Kreiraj novi kurs</Button>
                            </div>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Naziv</TableCell>
                                            <TableCell align="center">Cijena</TableCell>
                                            <TableCell align="center">Fakultet</TableCell>
                                            <TableCell align="center">Početak</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Akcije</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody component={DroppableComponent(onDragEnd)}>
                                        {courses && courses.map((course, index) => (
                                            <TableRow component={DraggableComponent(course.id, index)} key={course.id}>
                                                <TableCell component="th" scope="row" align="left">
                                                    {course.name}
                                                </TableCell>
                                                <TableCell align="center">{course.price ? `${course.price} KM` : '-'}</TableCell>
                                                <TableCell align="center">{FACULTY_MAP[course.faculty]}</TableCell>
                                                <TableCell align="center">{moment(course.startDate).format('DD.MM.YYYY.')}</TableCell>
                                                <TableCell align="center">{course.status[0] + course.status.substr(1).toLowerCase()}</TableCell>
                                                <TableCell align="center">
                                                    <EditIcon className="button-icon" onClick={() => editCourse(course)} />
                                                    {course.deleting ?
                                                        <CircularProgress className="action-progress" color="blac" /> :
                                                        <DeleteIcon className="button-icon" onClick={() => deleteCourse(course.id)} />}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                    {
                        activeTab === 'instructors' &&
                        <InstructorsTable
                            instructors={instructors}
                            addInstructor={addInstructor}
                            onDeleteInstructor={onDeleteInstructor}
                            editInstructor={editInstructor}
                        />
                    }
                    {
                        activeTab === 'texts' &&
                        <>
                            <div className="admin-dashboard-add-container">
                                <h1>Tekstovi</h1>
                            </div>
                            <Field
                                label="Tekst zaglavlja"
                                value={headerText}
                                onChange={onHeaderTextChange}
                            />
                            <Field
                                label="Tekst statusa zaglavlja"
                                value={headerStatusText}
                                onChange={onHeaderStatusTextChange}
                            />
                            <Field
                                label="Glavni tekst"
                                multiline
                                value={mainText}
                                onChange={onMainTextChange}
                            />
                            <Button color="primary" onClick={onSaveTexts}>
                                { savingTexts ? <CircularProgress className="button-progress" color="secondary" /> : 'Spasi'}
                            </Button>
                        </>
                    }
                    {
                        activeTab === 'analytics' &&
                        <div className="analytics-container">
                            <AnalyticsDashboard
                                authOptions={{
                                    serverAuth: {
                                        access_token: analyticsToken
                                    },
                                }}
                                renderCharts={() => {
                                    const gapi = window.gapi;
                                    const viewId = ANALYTICS_VIEW_ID;
                                    return (
                                        <div style={{ paddingBottom: '200px' }}>
                                            <SessionsByDateChart gapi={gapi} viewId={viewId} showPageViews showUsers />
                                            <PageViewsPerPathChart gapi={gapi} viewId={viewId} days={28} />
                                        </div>
                                    );
                                }}
                            />
                            <div className="analytics-overlay"></div>
                        </div>
                    }
                </div>
            </div>
            {
                showCourseForm &&
                <CourseForm
                    course={courseToEdit}
                    onClose={onCancelFormCourse}
                    nextOrder={courses.length}
                />
            }
            {
                showInstructorForm &&
                <InstructorForm
                    instructor={instructorToEdit}
                    onDone={onCancelFormInstructor}
                />
            }
        </>
    );
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: 'rgb(235,235,235)',
        color: 'rgb(235,235,235)',
    })
});

const DraggableComponent = (id, index) => (props) => {
    return (
        <Draggable draggableId={id.toString()} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
}

const DroppableComponent = (onDragEnd) => (props) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'1'} direction="vertical">
                {(provided) => {
                    return (
                        <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                            {props.children}
                            {provided.placeholder}
                        </TableBody>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
}

export default AdminDashboard;