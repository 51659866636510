import { useState } from 'react';
import { API } from '../utils/const';
import cookie from 'js-cookie';
import ReactGA from 'react-ga';

export const useUser = () => {
    const [user, setUser] = useState();

    const getUser = () => {
        return new Promise((resolve) => {
            if (user) {
                resolve(user);
                return;
            }

            const token = cookie.get('token')

            fetch(API + '/users/current', {
                headers: { 'authorization': token }
            }).then(res => {
                if (res.status !== 200) {
                    resolve(null);
                    return;
                }

                res.json().then(user => {
                    cookie.set('token', user.token, { expires: 10 * 365 });
                    setUser(user);
                    resolve(user);
                });
            }).catch(err => {
                console.error(err);
                resolve(null);
            });
        });
    };

    const setUserState = (usr) => {
        if (usr) {
            ReactGA.set({ userId: usr.id });
        } else {
            ReactGA.set({ userId: null });
        }
        setUser(usr);
    };

    return {
        getUser,
        setUser: setUserState
    };
};

export default useUser;
