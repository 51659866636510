import React, { useState, useEffect, useContext } from 'react';
import { API } from 'utils/const';
import UserContext from  'contexts/UserContext';
import { createInstructor, updateInstructor } from 'services/instructorService';
import InstructorForm from './InstructorForm';

const InstructorFormContainer = ({ instructor, onDone }) => {
    const [user, setUser] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [education, setEducation] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const { getUser } = useContext(UserContext);

    useEffect(() => {
        getUser().then(usr => {
            setUser(usr);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (instructor) {
            setFirstName(instructor.firstName);
            setLastName(instructor.lastName);
            setEducation(instructor.education);
            setDescription(instructor.description);
            if (instructor.imageKey) {
                setImage({
                    preview: instructor.imageUrl,
                    key: instructor.imageKey
                });
            }
        }
    }, [instructor]);

    const onSave = () => {
        let err = {};
        if (firstName.length === 0) {
            err.firstName = 'Morate unjeti ime';
        }
        if (lastName.length === 0) {
            err.lastName = 'Morate unjeti prezime';
        }
        if (description.length === 0) {
            err.description = 'Morate unjeti opis';
        }
        if (!image) {
            err.image = 'Morate unjeti sliku';
        }
        setError(err);

        if (Object.keys(err).length > 0) {
            return;
        }

        setLoading(true);
        if (image && image.data) {
            const formData = new FormData();
            formData.append("file", image.data);
            const options = {
                method: 'POST',
                body: formData,
                headers: { 'Authorization': user.token }
            };
            fetch(`${API}/misc/upload`,  options).then(res => {
                res.json().then(json => {
                    if (res.status !== 200) {
                        console.error(json.message);
                        setLoading(false);
                        return;
                    }

                    onUploadFinished(json.key);
                });
            });
        } else if (image && image.key) {
            onUploadFinished(image.key);
        } else {
            onUploadFinished();
        }
    };

    const onUploadFinished = (key = null) => {
        const body = { firstName, lastName, education, description, imageKey: key };
        if (instructor) {
            updateInstructor(instructor.id, body, user).then(() => {
                setLoading(false);
                onDone();
            });
            return;
        }
        createInstructor(body, user).then(() => {
            setLoading(false);
            onDone();
        });
    };

    const onClose = () => {
        onDone();
    };

    const onFirstNameChange = (e) => {
        setError({ ...error, firstName: null });
        setFirstName(e.target.value);
    };

    const onLastNameChange = (e) => {
        setError({ ...error, lastName: null });
        setLastName(e.target.value);
    };

    const onEducationChange = (e) => {
        setEducation(e.target.value);
    };

    const onDescriptionChange = (e) => {
        setError({ ...error, description: null });
        setDescription(e.target.value);
    };

    const onRemoveImage = () => {
        setImage(null);
    };

    const onUploadImage = (files) => {
        if (files && files.length > 0) {
            setError({ ...error, image: null });
            setImage({
                preview: URL.createObjectURL(files[0]),
                data: files[0]
            });
        }
    }

    return (
        <InstructorForm
            onSave={onSave}
            onClose={onClose}
            error={error}
            firstName={firstName}
            onFirstNameChange={onFirstNameChange}
            lastName={lastName}
            onLastNameChange={onLastNameChange}
            education={education}
            onEducationChange={onEducationChange}
            description={description}
            onDescriptionChange={onDescriptionChange}
            image={image}
            onRemoveImage={onRemoveImage}
            onUploadImage={onUploadImage}
            instructor={instructor}
            loading={loading}
        />
    );
};

export default InstructorFormContainer;
