import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Field from 'components/shared/Field';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FACULTY_MAP } from 'utils/text';
import './course-form.css';

const CourseForm = ({
    onSave, saving, onClose, error, name, onNameChange, location, onLocationChange,
    description, onDescriptionChange, startDate, onStartDateChange, price, onPriceChange, course,
    faculty, onFacultyChange, professors, onProfessorsChange, link, onLinkChange,
    numberOfLessons, onNumberOfLessonsChange, status, onStatusChange,
}) => {
    const faculties = Object.entries(FACULTY_MAP).map(el => ({ id: el[0], label: el[1] }));
    return (
        <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth = {'sm'}>
            <DialogTitle>{course ? 'Izmjena kursa' : 'Kreiranje kursa'}</DialogTitle>
            <div className="math-form">
                <Field
                    label="Naziv"
                    value={name}
                    onChange={onNameChange}
                    error={error.name}
                />
                <Field
                    label="Lokacija"
                    type="select"
                    items={[{ id: 'ONLINE', label: 'Online' }, { id: 'DRUGA_GIMNAZIJA', label: 'Druga gimnazija' }, { id: 'HYBRID', label: 'Hibridno (u učionici ili online)' }]}
                    value={location}
                    onChange={onLocationChange}
                />
                <Field
                    label="Datum početka"
                    type="date-picker"
                    value={startDate}
                    onChange={onStartDateChange}
                    error={error.startDate}
                />
                <Field
                    label="Cijena"
                    value={price}
                    onChange={onPriceChange}
                />
                <Field
                    label="Fakultet"
                    type="select"
                    items={faculties}
                    value={faculty}
                    onChange={onFacultyChange}
                />
                <Field
                    label="Predavači"
                    value={professors}
                    onChange={onProfessorsChange}
                />
                <Field
                    label="Link google forme"
                    value={link}
                    onChange={onLinkChange}
                    error={error.link}
                />
                <Field
                    label="Broj časova"
                    value={numberOfLessons}
                    onChange={onNumberOfLessonsChange}
                />
                <Field
                    label="Status"
                    type="select"
                    items={[{ id: 'OBJAVLJEN', label: 'Objavljen' }, { id: 'SAKRIVEN', label: 'Sakriven' }]}
                    value={status}
                    onChange={onStatusChange}
                />
                <Field
                    label="Opis"
                    value={description}
                    onChange={onDescriptionChange}
                    multiline
                    rows={10}
                    variant="outlined"
                />
            </div>

            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    Odustani
                </Button>
                <Button color="primary" onClick={onSave}>
                    { saving ? <CircularProgress className="button-progress" color="secondary" /> : 'Spasi'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CourseForm;