import React from 'react';
import './animation.css';

const Animation = () => {
    return (
        <div id="mainPartition">
            <div id="mainPartitionLeft">
                <div id="mainPartitionLeftCirclesPart">
                    <div id="middleCircle" title="MathSolutions">
                        <h1>MS</h1>
                    </div>
                    <div id="thirdCircle">
                        <div className="thirdCirclePart" title="Uvod u matematiku">
                            <img src="/animation/uvod_u_math.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart2" title="Osnove funkcija, domen">
                            <img src="/animation/funkcije.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart3" title="Izrazi i formule, binomni obrazac">
                            <img src="/animation/newton_binome.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart4" title="Linearna funkcija, jednačine i nejednačine">
                            <img src="/animation/equation.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart5" title="Sistemi linearnih jednačina">
                            <img src="/animation/sistemi_jednacina.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart6" title="Kvadratna funkcija, jednačine i nejednačine">
                            <img src="/animation/quadratic.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart7" title="Apsolutne vrijednosti">
                            <img src="/animation/absolute.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart8" title="Polinomi">
                            <img src="/animation/polinom_1.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart9" title="Iracionalne jednačine i nejednačine">
                            <img src="/animation/iracionalne.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart10" title="Eksponencijalne funkcije, jednačine i nejednačine">
                            <img src="/animation/exponential.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart11" title="Logaritamske funkcije, jednačine i nejednačine">
                            <img src="/animation/logaritam.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart12" title="Trigonometrijske funkcije, jednačine i nejednačine">
                            <img src="/animation/trigonometrija.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart13" title="Planimetrija">
                            <img src="/animation/planimetrija.png" alt="Animation icon" />
                        </div>
                        <div className="thirdCirclePart thirdCirclePart14" title="Stereometrija">
                            <img src="/animation/stereometrija.png" alt="Animation icon" />
                        </div>
                    </div>
                    
                    <div id="secondCircle">
                        <div className="secondCirclePart" title="Vektori">
                            <img src="/animation/vectors.png" alt="Animation icon" />
                        </div>
                        <div className="secondCirclePart secondCirclePart1" title="Analitička geometrija">
                            <img src="/animation/analiticka_geometrija.png" alt="Animation icon" />
                        </div>
                        <div className="secondCirclePart secondCirclePart2" title="Kompleksni brojevi">
                            <img src="/animation/kompleksni_1.png" alt="Animation icon" />
                        </div>
                        <div className="secondCirclePart secondCirclePart3" title="Brojni nizovi i redovi">
                            <img src="/animation/red.png" alt="Animation icon" />
                        </div>
                        <div className="secondCirclePart secondCirclePart4" title="Kombinatorika i logički zadaci">
                            <img src="/animation/kombinatorika.png" alt="Animation icon" />
                        </div>
                        <div className="secondCirclePart secondCirclePart5" title="Proporcije i procenti">
                            <img src="/animation/proporcije.png" alt="Animation icon" />
                        </div>
                        <div className="secondCirclePart secondCirclePart6" title="Brojni sistemi">
                            <img src="/animation/brojevni_sistemi.png" alt="Animation icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Animation;
