import React from 'react';
import './profile.css';

const Profile = ({ user }) => {
    return (
        <div>
            {user && (
                <div className="profile__text">
                    <p>Ime i prezime: <span className="profile__value">{ user.firstName + ' ' + user.lastName }</span></p>
                    <p>Email: <span className="profile__value">{ user.email }</span></p>
                </div>
            )}
        </div>
    );
};

export default Profile;
