import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import ToastContext from 'contexts/ToastContext';
import UserContext from 'contexts/UserContext';
import { API, NUMBER_ONLY_REGEX, URL_REGEX } from 'utils/const';
import CourseForm from './CourseForm';

const CourseFormContainer = (props) => {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('ONLINE');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(moment());
    const [price, setPrice] = useState('');
    const [faculty, setFaculty] = useState('ETF');
    const [professors, setProfessors] = useState('');
    const [link, setLink] = useState('');
    const [numberOfLessons, setNumberOfLessons] = useState('');
    const [status, setStatus] = useState('OBJAVLJEN');
    const [error, setError] = useState({});
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState();
    const { setToast } = useContext(ToastContext);
    const { getUser } = useContext(UserContext);

    useEffect(() => {
        getUser().then(usr => {
            setUser(usr);
        });

        if (props.course) {
            setName(props.course.name);
            setLocation(props.course.location);
            setDescription(props.course.description);
            setStartDate(moment(props.course.startDate));
            setPrice(props.course.price);
            setFaculty(props.course.faculty);
            setProfessors(props.course.professors);
            setLink(props.course.link);
            setNumberOfLessons(props.course.numberOfLessons);
            setStatus(props.course.status);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNameChange = (e) => {
        setName(e.target.value);
        setError(({ ...error, name: null }));
    };

    const onLocationChange = (e) => {
        setLocation(e.target.value);
    };

    const onDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const onStartDateChange = (date) => {
        setStartDate(date);
        setError(({ ...error, startDate: null }));
    };

    const onPriceChange = (e) => {
        if (e.target.value === '' || NUMBER_ONLY_REGEX.test(e.target.value)) {
            setPrice(e.target.value.replace(',', '.'));
        }
    };

    const onFacultyChange = (e) => {
        setFaculty(e.target.value);
    };

    const onProfessorsChange = (e) => {
        setProfessors(e.target.value);
    };

    const onNumberOfLessonsChange = (e) => {
        if (e.target.value === '' || NUMBER_ONLY_REGEX.test(e.target.value)) {
            setNumberOfLessons(e.target.value);
        }
    };

    const onLinkChange = (e) => {
        setLink(e.target.value);
        setError(({ ...error, link: null }));
    };

    const onStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const validate = () => {
        const err = {};

        if (!name) {
            err.name = 'Molimo unesite naziv kako biste nastavili dalje.';
        }

        if (!props.course && startDate && startDate.isBefore(moment().startOf('day'))) {
            err.startDate = 'Datum ne može biti u prošlosti.';
        }

        if (link && !URL_REGEX.test(link)) {
            err.link = 'Molimo unesite validan link do google forme.';
        }

        setError(err);
        return Object.keys(err).length === 0;
    }

    const onSave = () => {
        if (!validate()) {
            return;
        }

        setSaving(true);
        fetch(`${API}/courses/${props.course ? props.course.id : ''}`, {
            method: props.course ? 'PUT' : 'POST',
            body: JSON.stringify({
                name,
                description,
                startDate: startDate.toISOString(),
                location,
                price,
                faculty,
                professors,
                numberOfLessons,
                link,
                order: props.course ? props.course.order : props.nextOrder,
                status,
            }),
            headers: { 'Content-Type': 'application/json', 'Authorization': user.token }
        }).then(res => {
            setSaving(false);
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                setSaving(false);
                setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
                return;
            }

            setToast({ type: 'success', message: `Kurs ${name} je uspješno spašen` });
            props.onClose();
        }).catch(err => {
            console.error(err);
            setSaving(false);
            setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
        });
    }

    return (
        <CourseForm
            {...props}
            name={name}
            onNameChange={onNameChange}
            location={location}
            onLocationChange={onLocationChange}
            description={description}
            onDescriptionChange={onDescriptionChange}
            startDate={startDate}
            onStartDateChange={onStartDateChange}
            price={price}
            onPriceChange={onPriceChange}
            faculty={faculty}
            onFacultyChange={onFacultyChange}
            professors={professors}
            onProfessorsChange={onProfessorsChange}
            link={link}
            onLinkChange={onLinkChange}
            numberOfLessons={numberOfLessons}
            onNumberOfLessonsChange={onNumberOfLessonsChange}
            status={status}
            onStatusChange={onStatusChange}
            error={error}
            onSave={onSave}
            saving={saving}
            course={props.course}
        />
    )
};

export default CourseFormContainer;