import React from 'react';
import './contact-us.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const ContactUs = ({ fullName, onFullNameChange, email, onEmailChange, message, onMessageChange, onSubmit, error, saving }) => {
    return (
        <div className="contact-container">
            <div className="contact-box">
                <form onSubmit={onSubmit}>
                    <h3>Pošaljite poruku</h3>
                    <TextField
                        label="Ime i prezime"
                        value={fullName}
                        onChange={onFullNameChange}
                    />

                    <TextField
                        label="Email"
                        value={email}
                        onChange={onEmailChange}
                        error={error.email}
                        helperText={error.email}
                    />

                    <TextField
                        label="Poruka"
                        multiline
                        rows={8}
                        value={message}
                        onChange={onMessageChange}
                        error={error.message}
                        helperText={error.message}
                    />

                    <Button type="submit" variant="contained" color="primary">
                        { saving ? <CircularProgress className="button-progress" color="secondary" /> : 'Pošaljite'}
                    </Button>
                </form>
                <div className="contact-left-box">
                    <h4>Kontaktirajte nas</h4>
                    <div>
                        <MailIcon />
                        mathsolutions.ba@gmail.com
                    </div>
                    <div>
                        <PhoneIphoneIcon />
                        +387 62 018 673
                    </div>
                    <div className="contact-social">
                        <FacebookIcon onClick={() => { window.open('https://www.facebook.com/mathsolutions.ba', '_blank'); }} />
                        <InstagramIcon onClick={() => { window.open('https://www.instagram.com/mathsolutions.ba/', '_blank'); }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
