const checkResponse = (res) => {
    if (res.status === 401) {
        window.location = '/login';
        return false;
    } else if (!res.ok) {
        res.text().then(text => console.error(text));
        return false;
    }
    return true;
};

const apiService = {
    get: (url, user) => {
        return new Promise((resolve) => {
            fetch(url, {
                headers: { 'Authorization': user && user.token }
            }).then((res) => {
                if (checkResponse(res)) {
                    res.json().then((json) => {
                        resolve(json);
                    });
                }
            }).catch(() => {
                alert('Molimo provjerite vašu internet konekciju.');
            });
        });
    },
    post: (url, data, user) => {
        return new Promise((resolve) => {
            fetch(url, {
                headers: { 'Content-Type': 'application/json', 'Authorization': user && user.token },
                method: 'POST',
                body: JSON.stringify(data)
            }).then((res) => {
                if (checkResponse(res)) {
                    res.json().then((json) => {
                        resolve(json);
                    });
                }
            }).catch(() => {
                alert('Molimo provjerite vašu internet konekciju.');
            });
        });
    },
    put: (url, data, user) => {
        return new Promise((resolve) => {
            fetch(url, {
                headers: { 'Content-Type': 'application/json', 'Authorization': user && user.token },
                method: 'PUT',
                body: JSON.stringify(data)
            }).then((res) => {
                if (checkResponse(res)) {
                    res.json().then((json) => {
                        resolve(json);
                    });
                }
            }).catch(() => {
                alert('Molimo provjerite vašu internet konekciju.');
            });
        });
    },
    delete: (url, user) => {
        return new Promise((resolve) => {
            fetch(url, {
                headers: { 'Authorization': user && user.token },
                method: 'DELETE'
            }).then((res) => {
                if (checkResponse(res)) {
                    resolve();
                }
            }).catch(() => {
                alert('Molimo provjerite vašu internet konekciju.');
            });
        });
    }
};

export default apiService;
