import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Field from 'components/shared/Field';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Dropzone from 'react-dropzone';
import './instructor-form.css';

const InstructorForm = ({
    onSave, onClose, error, firstName, onFirstNameChange, lastName, onLastNameChange,
    education, onEducationChange, description, onDescriptionChange, image, onRemoveImage,
    onUploadImage, instructor, loading
}) => (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle>{instructor ? 'Izmjena kursa' : 'Kreiranje kursa'}</DialogTitle>
        <div className="math-form">
            <Field
                label="Ime"
                value={firstName}
                onChange={onFirstNameChange}
                error={error.firstName}
            />
            <Field
                label="Prezime"
                value={lastName}
                onChange={onLastNameChange}
                error={error.lastName}
            />
            <Field
                label="Zvanje"
                value={education}
                onChange={onEducationChange}
            />
            <Field
                label="Opis"
                value={description}
                onChange={onDescriptionChange}
                error={error.description}
                multiline
                rows={8}
            />
            <div className="form-controll">
                {image && (
                    <div className="image-preview-container">
                        <img className="image-preview" src={image.preview} alt="meal" />
                        <IconButton
                            onClick={onRemoveImage}
                            aria-label="remove"
                            color="secondary"
                        ><Delete /></IconButton>
                    </div>
                )}
                {!image && (
                    <Dropzone
                        multiple={false}
                        accept="image/*"
                        onDrop={onUploadImage}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                            <div {...getRootProps({ className: error.image ? 'dropzone dropzone--error' : 'dropzone' })}>
                                <input {...getInputProps()} />
                                <p>Dodaj sliku ovdje</p>
                            </div>
                            </section>
                        )}
                    </Dropzone>
                )}
            </div>
        </div>

        <DialogActions>
            {loading && <div className="progress-container"><CircularProgress color="secondary" /></div>}
            {!loading && (
                <>
                    <Button onClick={onSave} variant="contained" color="secondary">Spasi</Button>
                    &nbsp;&nbsp;
                    <Button onClick={onClose} variant="contained">Odustani</Button>
                </>
            )}
        </DialogActions>
    </Dialog>
);

export default InstructorForm;
