import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import TextsContext from 'contexts/TextsContext.js';
import { API, API_V2 } from 'utils/const';
import AdminDashboard from './AdminDashboard';
import { deleteInstructor } from 'services/instructorService';

const AdminDashboardContainer = ({ history }) => {
    const [activeTab, setActiveTab] = useState('courses');
    const [user, setUser] = useState();
    const [analyticsToken, setAnalyticsToken] = useState();
    const [courses, setCourses] = useState();
    const [showCourseForm, setShowCourseForm] = useState(false);
    const [courseToEdit, setCourseToEdit] = useState();
    const [savingTexts, setSavingTexts] = useState(false);
    const [headerText, setHeaderText] = useState('');
    const [headerStatusText, setHeaderStatusText] = useState('');
    const [mainText, setMainText] = useState('');
    const [instructors, setInstructors] = useState();
    const [showInstructorForm, setShowInstructorForm] = useState(false);
    const [instructorToEdit, setInstructorToEdit] = useState();
    const { getUser } = useContext(UserContext);
    const { setToast } = useContext(ToastContext);
    const { getTexts, setTexts } = useContext(TextsContext);

    useEffect(() => {
        getUser().then(usr => {
            if (!usr) {
                return;
            }
            if (!usr.admin) {
                history.push('/prijava');
            }
            setUser(usr);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!analyticsToken) {
            fetch(`${API_V2}/users/analytics-token`)
                .then(res => res.text())
                .then(token => setAnalyticsToken(token));
        }
    }, [analyticsToken]);

    useEffect(() => {
        if (showCourseForm) {
            return;
        }
        fetch(API + '/courses').then(res => {
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                return;
            }

            res.json().then(json => {
                setCourses(json);
            });
        }).catch(err => console.error(err));
    }, [showCourseForm]);

    useEffect(() => {
        if (showInstructorForm) {
            return;
        }
        fetch(API + '/instructors').then(res => {
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                return;
            }

            res.json().then(json => {
                setInstructors(json);
            });
        }).catch(err => console.error(err));
    }, [showInstructorForm]);

    useEffect(() => {
        getTexts().then(txt => {
            setHeaderText(txt.HEADER);
            setHeaderStatusText(txt.HEADER_STATUS);
            setMainText(txt.MAIN);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteCourse = (id) => {
        setCourses(courses.map(c => c.id === id ? { ...c, deleting: true } : c))
        fetch(`${API}/courses/${id}`, {
            method: 'DELETE',
            headers: { 'Authorization': user.token }
        }).then(res => {
            if (res.status !== 200) {
                res.text().then(text => {
                    setToast({ type: 'error', message: text });
                });
                return;
            }

            setCourses(courses.filter(c => c.id !== id));
        }).catch(err => {
            console.error(err);
            setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
        });
    };

    const editCourse = (course) => {
        setCourseToEdit(course);
        setShowCourseForm(true);
    };

    const onCancelFormCourse = () => {
        setShowCourseForm(false);
        setCourseToEdit(null);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newCourses = reorder(courses, result.source.index, result.destination.index);
        setCourses(newCourses);

        fetch(`${API}/courses/order`, {
            method: 'PUT',
            body: JSON.stringify(newCourses),
            headers: { 'Content-Type': 'application/json', 'Authorization': user.token }
        }).then(res => {
            if (res.status !== 200) {
                res.text().then(text => {
                    setToast({ type: 'error', message: text });
                });
                return;
            }
        }).catch(err => {
            console.error(err);
            setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        for (let i = 0; i < result.length; i++) {
            result[i].order = i;
        }
      
        return result;
    };

    const onSaveTexts = () => {
        setSavingTexts(true);

        const newTexts = {
            HEADER: headerText,
            HEADER_STATUS: headerStatusText,
            MAIN: mainText
        };

        fetch(API + '/misc/texts', {
            method: 'PUT',
            body: JSON.stringify(newTexts),
            headers: { 'Content-Type': 'application/json', 'Authorization': user.token }
        }).then(res => {
            setSavingTexts(false);
            if (res.status !== 200) {
                res.text().then(text => {
                    setToast({ type: 'error', message: text });
                });
                return;
            }

            setToast({ type: 'success', message: 'Tekstovi su uspješno spašeni.' });
            setTexts(newTexts);
        }).catch(err => {
            console.error(err)
            setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
        });
    };

    const onHeaderTextChange = (e) => {
        setHeaderText(e.target.value);
    };

    const onHeaderStatusTextChange = (e) => {
        setHeaderStatusText(e.target.value);
    };

    const onMainTextChange = (e) => {
        setMainText(e.target.value);
    };

    const addInstructor = () => {
        setShowInstructorForm(true)
    };

    const onDeleteInstructor = (id) => {
        setInstructors(instructors.map(c => c.id === id ? { ...c, deleting: true } : c));
        deleteInstructor(id, user).then(() => {
            setInstructors(instructors.filter(c => c.id !== id));
        });
    };

    const editInstructor = (instructor) => {
        setInstructorToEdit(instructor);
        setShowInstructorForm(true);
    };

    const onCancelFormInstructor = () => {
        setShowInstructorForm(false);
        setInstructorToEdit(null);
    };

    return (
        <AdminDashboard
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            analyticsToken={analyticsToken}
            courses={courses}
            showCourseForm={showCourseForm}
            setShowCourseForm={setShowCourseForm}
            deleteCourse={deleteCourse}
            editCourse={editCourse}
            courseToEdit={courseToEdit}
            onCancelFormCourse={onCancelFormCourse}
            onDragEnd={onDragEnd}
            onSaveTexts={onSaveTexts}
            savingTexts={savingTexts}
            headerText={headerText}
            onHeaderTextChange={onHeaderTextChange}
            headerStatusText={headerStatusText}
            onHeaderStatusTextChange={onHeaderStatusTextChange}
            mainText={mainText}
            onMainTextChange={onMainTextChange}
            instructors={instructors}
            addInstructor={addInstructor}
            instructorToEdit={instructorToEdit}
            onDeleteInstructor={onDeleteInstructor}
            editInstructor={editInstructor}
            showInstructorForm={showInstructorForm}
            onCancelFormInstructor={onCancelFormInstructor}
        />
    );
};

export default withRouter(AdminDashboardContainer);