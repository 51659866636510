import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextsContext from 'contexts/TextsContext';
import Home from './Home.jsx';
import { API } from 'utils/const';

const HomeContainer = ({ history }) => {
    const [texts, setTexts] = useState({});
    const [courses, setCourses] = useState();
    const [connected, setConnected] = useState(false);
    const [instructors, setInstructors] = useState();
    const { getTexts } = useContext(TextsContext);

    useEffect(() => {
        fetch(API + '/courses').then(res => {
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                return;
            }

            res.json().then(json => {
                setCourses(json.filter(c => c.status === 'OBJAVLJEN'));
            });
        }).catch(err => console.error(err));
    }, []);

    useEffect(() => {
        getTexts().then(txt => {
            if (txt.MAIN) {
                txt.MAIN = '<p>' + txt.MAIN.split('\n').join('</p><p>') + '</p>';
            }
            setTexts(txt);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!texts || !texts.MAIN || !courses || connected) {
            return;
        }
        let newMain = texts.MAIN;
        for (const course of courses) {
            const trimName = course.name.replace(/ \(.+\)/, '');
            if (newMain.includes(trimName)) {
                newMain = newMain.split(trimName).join(`<a href="/kursevi#${course.name}">${trimName}</a>`);
            }
        }
        setConnected(true);
        setTexts({ ...texts, MAIN: newMain });
    }, [texts, courses, connected]);

    useEffect(() => {
        fetch(API + '/instructors').then(res => {
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                return;
            }

            res.json().then(json => {
                setInstructors(json);
            });
        }).catch(err => console.error(err));
    }, []);

    const openCourses = () => {
        history.push('/kursevi');
    };

    return (
        <Home
            openCourses={openCourses}
            texts={texts}
            courses={courses}
            instructors={instructors}
        />
    );
}

export default withRouter(HomeContainer);
