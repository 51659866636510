import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(() => ({
    formControll: { marginBottom: 20, width: '100%' }
}));

const Field = (props) => {
    const { type, label, items } = props;
    const classes = useStyles();

    return (
        <div>
            {
                (!type || type === 'text') &&
                <FormControl className={classes.formControll}>
                    <TextField {...props} error={!!props.error} helperText={props.error} />
                </FormControl>
            }
            {
                type === 'select' &&
                <FormControl className={classes.formControll}>
                    <InputLabel id={`select-${label}`}>{label}</InputLabel>
                    <Select
                        className="form-controll"
                        labelId={`select-${label}`}
                        {...props}
                    >
                        {items.map(i => <MenuItem key={i.id} value={i.id}>{i.label}</MenuItem>)}
                    </Select>
                </FormControl>
            }
            {
                type === 'date-picker' &&
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD. MM. yyyy."
                        margin="normal"
                        KeyboardButtonProps={{
                            'aria-label': 'Izaberi datum',
                        }}
                        {...props}
                        helperText={props.error}
                    />
                </MuiPickersUtilsProvider>
            }
        </div>
    );
};

export default Field
