import { useState, useEffect } from 'react';

export const useToast = () => {
    const [toast, setToast] = useState();

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 4000);
        }
    }, [toast])

    return {
        toast,
        setToast
    };
};

export default useToast;
