import React, { useEffect, useState } from 'react';
import { API } from 'utils/const';
import { getAllInstructors } from 'services/instructorService';
import Courses from './Courses';

const CoursesCountainer = () => {
    const [originalCourses, setOriginalCourses] = useState();
    const [courses, setCourses] = useState();
    const [instructors, setInstructors] = useState();

    useEffect(() => {
        fetch(API + '/courses').then(res => {
            if (res.status !== 200) {
                res.text().then(text => console.error(text));
                return;
            }

            res.json().then(json => {
                const filtered = json.filter(c => c.status === 'OBJAVLJEN');
                setOriginalCourses(filtered);
                setCourses(filtered);
            });
        }).catch(err => console.error(err));
    }, []);

    useEffect(() => {
        getAllInstructors().then(res => {
            setInstructors(res);
        })
    }, []);

    useEffect(() => {
        if (!courses) {
            return;
        }
        setTimeout(() => {
            const { hash } = window.location;
            if (hash !== '') {
                const id = decodeURI(hash.replace('#', ''));
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }
        }, 400);
    }, [courses]);

    const openForm = (url) => window.open(url, '_blank', 'noopener,noreferrer');

    const filterCourses = (faculty) => {
        setCourses(originalCourses.filter(c => c.faculty === faculty));
    };

    const showDescription = (id) => {
        setCourses(courses.map(c => c.id === id ? { ...c, showDescription: true } : c));
    };

    return (
        <Courses
            openForm={openForm}
            originalCourses={originalCourses}
            courses={courses}
            filterCourses={filterCourses}
            showDescription={showDescription}
            instructors={instructors}
        />
    );
};

export default CoursesCountainer;
