import React, { useEffect, useState, useContext } from 'react';
import UserContext from 'contexts/UserContext';
import Profile from './Profile';

const ProfileContainer = () => {
    const [user, setUser] = useState();
    const { getUser } = useContext(UserContext);

    useEffect(() => {
        getUser().then(usr => {
            setUser(usr);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Profile
            user={user}
        />
    );
}

export default ProfileContainer;
