import React, { useState, useContext } from 'react';
import ContactUs from './ContactUs';
import { API } from 'utils/const';
import ToastContext from 'contexts/ToastContext';
import { EMAIL_REGEX } from 'utils/const';

const ContactUsContainer = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState({});
    const [saving, setSaving] = useState(false);
    const { setToast } = useContext(ToastContext);

    const onFullNameChange = e => {
        setFullName(e.target.value);
    };

    const onEmailChange = e => {
        setEmail(e.target.value);
        setError(err => ({ ...err, email: null }));
    };

    const onMessageChange = e => {
        setMessage(e.target.value);
        setError(err => ({ ...err, message: null }));
    };

    const validate = () => {
        const err = {};

        if (!email) {
            err.email = 'Molimo unesite email kako biste nastavili dalje.';
        } else if (!EMAIL_REGEX.test(email)) {
            err.email = 'Molimo provjerite tačnost emaila.'
        }

        if (!message) {
            err.message = 'Molimo unesite poruku kako biste nastavili dalje.'
        }

        setError(err);
        return Object.keys(err).length === 0;
    }

    const onSubmit = e => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setSaving(true);

        fetch(API + '/misc/contact', {
            method: 'PUT',
            body: JSON.stringify({ fullName, email, message }),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setSaving(false);
            if (res.status !== 200) {
                setToast({ type: 'error', message: 'Neuspjesno slanje poruke.' });
                return;
            }

            setToast({ type: 'success', message: 'Vaša poruka je uspješno poslana.' });
            setFullName('');
            setEmail('');
            setMessage('');
        }).catch(err => {
            console.error(err);
            setSaving(false);
            setToast({ type: 'error', message: 'Desila se neočekivana greška.' });
        })
    };

    return (
        <ContactUs
            fullName={fullName}
            onFullNameChange={onFullNameChange}
            email={email}
            onEmailChange={onEmailChange}
            message={message}
            onMessageChange={onMessageChange}
            onSubmit={onSubmit}
            error={error}
            saving={saving}
        />
    );
};

export default ContactUsContainer;
